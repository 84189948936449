.login-form {
  width: 30%;
  margin: 50px auto 0;
  border: 5px solid black;
  background: #f15a22;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
  font-weight: bold;
}

.error {
  font-weight: bold;
  color: white;
}
