.header {
  font-size: 50px;
  text-align: center;
  background: #f15a22;
  height: 200px;
  padding: 70px 0;
}

.logout-div {
  text-align: center;
}
