.query-form {
  margin: 10px auto 0;
  border: 2px solid black;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
}

.data-details {
  width: 75%;
  margin: 50px auto 0;
  border: 5px solid black;
  background: #f15a22;
  border-radius: 5px;
  padding: 5px;

  font-weight: bold;
}

.query-title,
.results-title {
  padding-bottom: 20px;
  text-align: center;
  text-decoration: underline;
}

.reset-div {
  text-align: right;
}
